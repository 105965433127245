export default class Planner {
    constructor($el) {
        this.$el = $el;
        this.$$commentForm = this.$el.querySelectorAll('.js-plannerCommentForm');

        this.initListeners();
    }

    initListeners() {
        this.$$commentForm.forEach(($commentForm) => {
            const $textarea = $commentForm.querySelector('textarea');

            if ($textarea) {
                $textarea.addEventListener('blur', this.onCommentBlur.bind(this), false);
            }
        });
    }

    async onCommentBlur(e) {
        const $textarea = e.target;
        const $form = $textarea.closest('form');

        if ($form && $textarea.value !== $textarea.defaultValue) {
            const $id = $form.querySelector('input[name="id"]');
            const body = new FormData($form);
            const url = $form.action;
            const method = $form.method;
    
            fetch(url, { body, method })
                .then(response => response.json())
                .then(json => {
                    $id.value = json.id ?? '';
                    $textarea.defaultValue = json.comment ?? '';

                    if (json.toast) {
                        document.dispatchEvent(new CustomEvent('toast', { detail: json.toast }));
                    }
                });
        }
    }
}
