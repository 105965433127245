import Cookies from 'js-cookie';

export default class Tabs {
    $el;
    $$tab;

    constructor($el) {
        this.$el = $el;
        this.$$tab = this.$el.querySelectorAll(`[role='tab']`)
        this.id = this.$el.id ?? null;

        this.initListeners();
        this.dispatchActivateEvent();

        if (this.id) {
            const lastTabId = Cookies.get(`${this.id}.last-tab`);

            if (lastTabId) {
                const $tab = document.getElementById(lastTabId);

                if ($tab) {
                    this.setActiveTab($tab);
                }
            }
        }
    }

    dispatchActivateEvent() {
        const $activeTab = this.$el.querySelector(`[role=tab][aria-selected=true]`);

        if ($activeTab) {
            const activeTabId = $activeTab.id ?? null;
            this.$el.dispatchEvent(new CustomEvent(`tabs.activate.${activeTabId}`, {bubbles: true}));
            this.$el.dispatchEvent(new CustomEvent(`tabs.activate`, {
                detail: {
                    id: activeTabId
                }
            }, { bubbles: true }));
        }
    }

    initListeners() {
        this.$$tab.forEach($tab => {
            if ($tab.closest('.js-tabs') === this.$el) {
                $tab.addEventListener('pointerdown', this.onTabPointerDown.bind(this));
            }
        });
    }

    onTabPointerDown(e) {
        e.preventDefault();
        const $tab = e.currentTarget;
        this.setActiveTab($tab);
    }

    setActiveTab($tab) {
        const $tabPanel = document.getElementById($tab.getAttribute('aria-controls'));

        this.$el.querySelectorAll(`[role='tab']`).forEach($tab => {
            if ($tab.closest('.js-tabs') === this.$el) {
                $tab.setAttribute('aria-selected', false);
            }
        });

        this.$el.querySelectorAll(`[role='tabpanel']`).forEach($tabPanel => {
            if ($tab.closest('.js-tabs') === this.$el) {
                $tabPanel.setAttribute('hidden', true);
            }
        });

        $tab.setAttribute('aria-selected', true);

        if ($tabPanel) {
            $tabPanel.removeAttribute('hidden');
        }

        if (this.id) {
            Cookies.set(`${this.id}.last-tab`, $tab.getAttribute('id'), { expires: 7, sameSite: 'strict' });
        }

        this.dispatchActivateEvent();
    }
}
