export default class WeekPicker {
    $el;
    $monthNavNext;
    $monthNavPrev;
    $container;
    baseUrl;
    currentWeek;
    currentYear;
    dayNames;
    monthNames;
    now;

    constructor($el) {
        this.$el = $el;
        this.$monthNavNext = this.$el.querySelector('.js-weekPickerMonthNavNext');
        this.$monthNavPrev = this.$el.querySelector('.js-weekPickerMonthNavPrev');
        this.$container = this.$el.querySelector('.js-weekPickerContainer');
        this.baseUrl = '/?action=70';
        this.currentWeek = null;
        this.currentYear = null;
        this.dayNames = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
        this.monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        this.now = new Date();

        const $config = this.$el.querySelector('script[data-week-picker-config]');

        if ($config) {
            const config = JSON.parse($config.textContent);

            if (config && config.dayNames && config.monthNames) {
                this.baseUrl = config.baseUrl || this.baseUrl;
                this.currentWeek = +config.currentWeek;
                this.currentYear = +config.currentYear;
                this.dayNames = config.dayNames;
                this.monthNames = config.monthNames;
            }
        }

        this.renderCalendars();
        this.initListeners();
    }
    
    getWeekNumber(d) {
        d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
        d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
        const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
        const weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
        return weekNo;
    }

    initListeners() {
        this.$monthNavNext.addEventListener('click', this.onMonthNextClick.bind(this));
        this.$monthNavPrev.addEventListener('click', this.onMonthPrevClick.bind(this));
    }

    onMonthNextClick() {
        this.$container.innerHTML = '';
        this.now = new Date(this.now.getFullYear(), this.now.getMonth() + 1, 1);
        this.renderCalendars();
    }

    onMonthPrevClick() {
        this.$container.innerHTML = '';
        this.now = new Date(this.now.getFullYear(), this.now.getMonth() - 1, 1);
        this.renderCalendars();
    }

    renderCalendar(month, year) {
        // Maak de container
        const $calendar = document.createElement('div');
        $calendar.className = 'weekPicker__calendar';

        // Maak de <h3>
        const $heading = document.createElement('h3');
        $heading.textContent = this.monthNames[month] + ' ' + year;
        $calendar.appendChild($heading);

        // Maak de kalender tabel
        const $table = document.createElement('table');
        $table.setAttribute('cellspacing', '0');

        // Maak de <thead> met de dagen van de week
        const $thead = document.createElement('thead');
        const $dayNamesRow = document.createElement('tr');

        // Voeg lege <th> toe als eerste kolom (weeknummer kolom)
        const $weekCell = document.createElement('th');
        $weekCell.className = 'weekPicker__weekNumber';
        // $weekCell.textContent = 'W';
        $dayNamesRow.appendChild($weekCell);

        for (let i = 0; i < 7; i++) {
            const $dayNameCell = document.createElement('th');
            $dayNameCell.setAttribute('scope', 'col');
            $dayNameCell.textContent = this.dayNames[i];
            $dayNamesRow.appendChild($dayNameCell);
        }

        $thead.appendChild($dayNamesRow);
        $table.appendChild($thead);

        // Maak de <tbody> met de weken van de maand
        const $tbody = document.createElement('tbody');

        // Bepaal eerste dag van deze maand
        const firstDay = new Date(year, month, 1);

        // Begin op maandag (sorry, dit % trucje komt gewoon van internet haha)
        const startingDay = (firstDay.getDay() + 6) % 7;

        // Bepaal het aantal dagen in deze maand
        const daysInMonth = new Date(year, month + 1, 0).getDate();

        let date = 1;
        let weekNumber = this.getWeekNumber(firstDay);

        // Maak rijen voor de kalender
        for (let i = 0; i < 6; i++) {
            const $tr = document.createElement('tr');
            let rowHasDays = false;

            // Maak eerste kolom voor weeknummer
            const $weekNumberCell = document.createElement('th');
            $weekNumberCell.className = 'weekPicker__weekNumber';
            $weekNumberCell.setAttribute('scope', 'row');
            $weekNumberCell.textContent = weekNumber;
            $tr.appendChild($weekNumberCell);

            // Maak kolommen voor de dagen van de week
            for (let j = 0; j < 7; j++) {
                const $td = document.createElement('td');

                if (i === 0 && j < startingDay) {
                    // Lege kolom voor de eerste dag van de maand
                    $td.textContent = '';
                } else if (date > daysInMonth) {
                    // Lege kolom na de laatste dag van de maand
                    $td.textContent = '';
                } else {
                    // Kolom met datum
                    $td.textContent = date;
                    rowHasDays = true;
                    date++;
                }

                $tr.appendChild($td);
            }

            if (rowHasDays) {
                $tr.dataset.week = weekNumber;
                $tr.dataset.year = year;

                if (weekNumber == this.currentWeek && year == this.currentYear) {
                    $tr.classList.add('is-currentWeek');
                }

                $tr.addEventListener('click', (e) => {
                    const week = e.currentTarget.dataset.week;
                    const year = e.currentTarget.dataset.year;
                    location.href = this.baseUrl + '&week=' + ("0" + week).slice(-2) + '&year=' + year;
                });

                $tbody.appendChild($tr);
                weekNumber++;

                if (weekNumber > 52) {
                    weekNumber = 1;
                }
            }
        }

        $table.appendChild($tbody);
        $calendar.appendChild($table);
        this.$container.appendChild($calendar);
    }

    renderCalendars() {
        const nowPlus1 = new Date(this.now.getFullYear(), this.now.getMonth() + 1, 1);
        const nowPlus2 = new Date(this.now.getFullYear(), this.now.getMonth() + 2, 1);

        this.renderCalendar(this.now.getMonth(), this.now.getFullYear());
        this.renderCalendar(nowPlus1.getMonth(), nowPlus1.getFullYear());
        this.renderCalendar(nowPlus2.getMonth(), nowPlus2.getFullYear());
    }

    

}