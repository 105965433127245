export default class Uploader {
    $el;

    constructor($el) {
        this.$el = $el;
        this.$files = this.$el.querySelector('.js-uploaderFiles');
        this.$inputCamera = this.$el.querySelector('.js-uploaderInputCamera');
        this.$inputFile = this.$el.querySelector('.js-uploaderInputFile');
        this.$mailCount = this.$el.querySelector('.js-uploaderMailCount');
        this.$mailSubmit = this.$el.querySelector('.js-uploaderMailSubmit');
        this.confirmDelete = this.$el.dataset.uploaderConfirmDelete;
        this.data = this.$el.dataset.uploaderData;
        this.extra = this.$el.dataset.uploaderExtra;
        this.id = this.$el.dataset.uploaderId;
        this.req = null;
        this.type = this.$el.dataset.uploaderType;
        this.unique = `${this.type}_${this.id}`;
        this.url = this.$el.dataset.uploaderUrl;

        this.initFileList();
        this.initListeners();
    }

    delete(id) {
        const data = new FormData();
        data.append('del', id);
        data.append('uploader_id', this.id);
        data.append('uploader_type', this.type);

        if (confirm(this.confirmDelete)) {
            fetch('/Request_Handler.php?REQ=23', {method: 'POST', body: data})
                    .then(response => response.json())
                    .then(data => {
                        if (data.toast ?? null) {
                            document.dispatchEvent(new CustomEvent('toast', {detail: data.toast}));
                        }

                        if (data.files_html ?? null) {
                            this.updateFileList(data.files_html);
                        }
                    });
        }
    }

    initFileList() {
        this.$$fileActions = this.$el.querySelectorAll('.js-uploaderFileAction');
        this.$$files = this.$el.querySelectorAll(`input[name="selectfiles[]"]`);
    }

    initListeners() {
        for (const $fileAction of this.$$fileActions) {
            $fileAction.addEventListener('click', this.onFileActionClick.bind(this));
        }

        for (const $file of this.$$files) {
            $file.addEventListener('click', this.onFileToggle.bind(this), false);
        }

        this.$inputFile.addEventListener('change', (e) => {
            const files = e.target.files;
            for (const file of files) {
                this.upload(file); // Upload elk bestand afzonderlijk
            }
        }, false);

        this.$inputCamera.addEventListener('change', (e) => {
            const file = e.target.files[0];

            this.resizeImage(file, 1920)
                    .then(resizedImage => {
                        this.upload(resizedImage);
                    })
                    .catch(error => {
                        console.error(error);
                    });

        }, false);

        this.$mailSubmit.addEventListener('click', this.onMailSubmit.bind(this), false);
    }

    onFileActionClick(e) {
        e.preventDefault();
        const action = e.currentTarget.dataset.uploaderAction;

        if (action === 'delete') {
            const id = e.currentTarget.dataset.uploaderFileId;
            this.delete(id);
        }

        if (action === 'print') {
            const url = e.currentTarget.dataset.uploaderFileUrl;
            this.print(url);
        }

        if (action === 'view') {
            const url = e.currentTarget.dataset.uploaderFileUrl;
            this.view(url);
        }
    }

    onFileToggle(e) {
        const $$checked = this.$el.querySelectorAll(`input[name="selectfiles[]"]:checked`);
        this.$mailCount.textContent = $$checked.length;
        this.hasSelectedFiles = $$checked.length > 0;
    }

    onMailSubmit() {
        const $form = this.$el.closest('form');
        const data = new FormData($form);
        data.append('a', this.type);
        data.append('b', this.id);
        data.append('c', this.extra);

        fetch('/Request_Handler.php?REQ=66', {method: 'POST', body: data})
                .then(response => response.json())
                .then(data => {
                    if (data.toast ?? null) {
                        document.dispatchEvent(new CustomEvent('toast', {detail: data.toast}));
                    }

                    // Uncheck all files
                    for (const $file of this.$$files) {
                        $file.checked = false;
                    }

                    // Reset hasSelectedFiles
                    this.hasSelectedFiles = false;
                });
    }

    onUploadComplete(e) {
        const response = JSON.parse(this.req.responseText);

        if (response.toast ?? null) {
            document.dispatchEvent(new CustomEvent('toast', {detail: response.toast}));
        }

        if (response.files_html ?? null) {
            this.updateFileList(response.files_html);
        }

        // Reset uploading state
        this.reset();
    }

    onUploadProgress(e) {
        this.$el.style.setProperty('--upload-progress', e.loaded / e.total);
    }

    print(url) {
        fetch(url)
                .then(response => response.blob())
                .then(blob => {
                    const fileUrl = URL.createObjectURL(blob);
                    const $iframe = document.createElement('iframe');

                    document.body.appendChild($iframe);
                    $iframe.src = fileUrl;
                    $iframe.style.position = 'fixed';
                    $iframe.style.top = '0';
                    $iframe.style.left = '0';
                    $iframe.style.width = '100%';
                    $iframe.style.height = '100%';
                    $iframe.style.border = 'none';
                    $iframe.style.display = 'none';

                    $iframe.onload = () => {
                        const doc = $iframe.contentDocument || $iframe.contentWindow.document;

                        // Add print-specific styles
                        const style = doc.createElement('style');
                        style.textContent = `
                    @media print {
                        img {
                            max-width: 100%;
                            height: auto;
                            display: block;
                            margin: 0 auto;
                        }
                        body {
                            margin: 0;
                            padding: 0;
                        }
                    }
                `;
                        doc.head.appendChild(style);

                        $iframe.focus();
                        $iframe.contentWindow.print();
                    };
                });
    }

    updateFileList(html) {
        this.$files.innerHTML = html;
        this.initFileList();
        //this.initListeners();
    }

    reset() {
        this.$el.style.setProperty('--upload-progress', 0);
        this.isUploading = false;
    }

    resizeImage(file, maxSize) {
        const $canvas = document.createElement('canvas');
        const image = new Image();
        const reader = new FileReader();

        const dataURLToBlob = (dataURL) => {
            const bytes = dataURL.split(',')[0].indexOf('base64') >= 0 ?
                    atob(dataURL.split(',')[1]) :
                    dataURL.split(',')[1];
            const mime = dataURL.split(',')[0].split(':')[1].split(';')[0];
            const max = bytes.length;
            const iA = new Uint8Array(max);

            for (let i = 0; i < max; i++) {
                iA[i] = bytes.charCodeAt(i);
            }

            return new Blob([iA], {type: mime});
        };

        const resize = () => {
            let w = image.width;
            let h = image.height;

            if (w > h) {
                if (w > maxSize) {
                    h *= maxSize / w;
                    w = maxSize;
                }
            } else {
                if (h > maxSize) {
                    w *= maxSize / h;
                    h = maxSize;
                }
            }

            $canvas.width = w;
            $canvas.height = h;
            $canvas.getContext('2d').drawImage(image, 0, 0, w, h);
            const dataURL = $canvas.toDataURL('image/jpeg');
            return dataURLToBlob(dataURL);
        };

        return new Promise((resolve, reject) => {
            if (!file.type.match(/image.*/)) {
                reject(new Error('File is not an image.'));
                return;
            }

            reader.onload = (readerEvent) => {
                image.onload = () => {
                    return resolve(resize());
                };
                image.src = readerEvent.target.result;
            };

            reader.readAsDataURL(file);
        });
    }

    async upload(file) {
        const data = new FormData();
        data.append('data', this.type);
        data.append('id', this.id);

        if (file instanceof File) {
            data.append('file', file);
        } else {
            data.append('file', new File([file], 'test.jpg', {
                lastModified: new Date().getTime(),
                type: file.type
            }));
        }

        // Set uploading state
        this.isUploading = true;

        // Upload file
        this.req = new XMLHttpRequest();
        this.req.addEventListener('load', this.onUploadComplete.bind(this), false);
        this.req.upload.addEventListener('progress', this.onUploadProgress.bind(this), false);
        this.req.open('POST', this.url);
        this.req.send(data);
    }

    view(url) {
        const $iframe = document.createElement('iframe');
        $iframe.src = url;

        const $overlay = document.createElement('div');
        $overlay.classList.add('uploader__overlay');
        $overlay.appendChild($iframe);
        $overlay.addEventListener('click', () => {
            document.body.removeChild($overlay);
        });

        document.body.appendChild($overlay);
    }

    get hasSelectedFiles() {
        return this.$el.classList.contains('has-selectedFiles');
    }

    set hasSelectedFiles(hasSelectedFiles) {
        this.$el.classList.toggle('has-selectedFiles', hasSelectedFiles);
    }

    get isUploading() {
        return this.$el.classList.contains('is-uploading');
    }

    set isUploading(isUploading) {
        this.$el.classList.toggle('is-uploading', isUploading);
    }
}
