export default class Toast {
    $el;
    defaultTimeout;

    constructor($el, options = {}) {
        this.$el = $el;
        this.defaultTimeout = options.defaultTimeout || 5000; // Standaard timeout in ms
        this.initListeners();
    }

    add(message, type = null, options = {}) {
        const { timeout = this.defaultTimeout, title = null, icon = null, redirect = null } = options;

        const $toast = document.createElement('div');
        $toast.className = 'toast__message is-enter';

        // Optioneel icoon
        if (icon) {
            const $icon = document.createElement('span');
            $icon.className = 'toast__icon material-symbols-outlined';
            $icon.innerText = icon; // Verwacht de naam van het Material Symbol
            $toast.appendChild($icon);
        }

        // Optionele titel
        if (title) {
            const $title = document.createElement('strong');
            $title.className = 'toast__title';
            $title.innerText = title;
            $toast.appendChild($title);
        }

        // Bericht
        const $message = document.createElement('div');
        $message.className = 'toast__text';
        $message.innerText = message;
        $toast.appendChild($message);

        // Type-styling
        if (type) {
            $toast.classList.add(`toast__message--${type}`);
        }

        // Animatie event listeners
        $toast.addEventListener('animationend', (e) => this.onMessageAnimationEnd(e, redirect), false);
        $toast.addEventListener('click', this.onMessageClick.bind(this), false);

        this.$el.appendChild($toast);

        // Automatische sluiting na timeout
        if (timeout > 0) {
            setTimeout(() => {
                $toast.classList.remove('is-enter');
                $toast.classList.add('is-leave');
            }, timeout);
        }
    }

    initListeners() {
        document.addEventListener('toast', (e) => {
            console.log(e.detail);
            this.add(e.detail.message, e.detail.type ?? null, e.detail.options ?? {});
        }, false);

        document.dispatchEvent(new CustomEvent('toast:ready'));
    }

    onMessageAnimationEnd(e, redirect = null) {
        const $toast = e.currentTarget;

        if (e.animationName === 'toast-enter') {
            $toast.classList.remove('is-enter');
        }

        if (e.animationName === 'toast-timer') {
            $toast.classList.add('is-leave');
        }

        if (e.animationName === 'toast-leave') {
            $toast.remove();

            // Voer redirect uit als opgegeven
            if (redirect) {
                window.location.href = redirect;
            }
        }
    }

    onMessageClick(e) {
        const $toast = e.currentTarget;
        $toast.classList.remove('is-enter');
        $toast.classList.add('is-leave');
    }
}
